@font-face {
  font-family: "Lato";
  src: local("Lato Medium"), local("Lato-Medium"), url("assets/fonts/lato-medium.woff2") format("woff2"),
    url("assets/fonts/lato-medium.woff") format("woff"), url("assets/fonts/lato-medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: local("Lato Semibold"), local("Lato-Semibold"), url("assets/fonts/lato-semibold.woff2") format("woff2"),
    url("assets/fonts/lato-semibold.woff") format("woff"), url("assets/fonts/lato-semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: local("Lato Bold"), local("Lato-Bold"), url("assets/fonts/lato-bold.woff2") format("woff2"),
    url("assets/fonts/lato-bold.woff") format("woff"), url("assets/fonts/lato-bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: local("Lato Heavy"), local("Lato-Heavy"), url("assets/fonts/lato-heavy.woff2") format("woff2"),
    url("assets/fonts/lato-heavy.woff") format("woff"), url("assets/fonts/lato-heavy.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
